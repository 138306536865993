import * as types from "../redux-constants/teamsConstants"

export const TEAMS_IS_CONFIRMATION_MODAL = (isConfirmationModal) => ({
    type: types.TEAMS_IS_CONFIRMATION_MODAL,
    payload: {
        isConfirmationModal,
    }
})

export const SET_LICENCE_PACKAGE = (licencePackage) => ({
    type: types.SET_LICENCE_PACKAGE,
    payload: {
        licencePackage,
    }
})

export const CLEAN_TEAMS_STATE = () => ({
    type: types.CLEAN_TEAMS_STATE,
    payload: {}
})