import {useState, useEffect} from "react"
import {NavLink, Link} from "react-router-dom"
import {useTranslation} from "react-i18next"
import {connect} from "react-redux"
import {globalSelector} from "../../redux/selectors/globalSelector"
import {REFRESH_COGNITO_TOKEN} from "../../redux/actions/sessionActions"
import {HANDLE_TYPE} from "../../redux/actions/localActions"
import {SET_LICENCE_PACKAGE} from "../../redux/actions/teamsActions"
import currentAuthSession from "../../aws/cognito/currentAuthSession"
import axios from "axios"
import {API_TEAMS} from "../../constants/apiUrls"
import neurowideStudioLogo from "../../assets/neurowide-studio.svg"
import neurowideStudioMin from "../../assets/neurowide-studio-min.svg"
import doubleArrowLeftIcon from "../../assets/icon/double-arrow-left.svg"
import homeNavIcon from "../../assets/icon/home-nav.svg"
import projectsNavIcon from "../../assets/icon/projects-nav.svg"
import lexiconsNavIcon from "../../assets/icon/lexicons-nav.svg"
import manageAccountIcon from "../../assets/icon/manage-account.svg"
import teamNavIcon from "../../assets/icon/team-nav.svg"
import nolejLogo from "../../assets/img/nolej-logo.svg"
import helpIcon from "../../assets/icon/help.svg"
import creditsNavIcon from "../../assets/icon/credits-nav.svg"
import RequireIsManager from "../../tools/RequireIsManager"
import RequireIsAdmin from "../../tools/RequireIsAdmin"

const getTeamInfos = (idTeam, headersRequest) => {
    return axios.get(`${API_TEAMS}/${idTeam}`, headersRequest)
    .then((response) => {
        return {
            licence_package: response.data.team.licence_package,
            nb_credits: response.data.team.nb_credits,
        }
    })
    .catch ((error) => {
        console.error("GET /teams/team: ", error)
    })
}

function MainNavigation({sessionState, localState, teamsState, REFRESH_COGNITO_TOKEN, HANDLE_TYPE, SET_LICENCE_PACKAGE}) {
    const {t} = useTranslation()
    let [minNavigation, setMinNavigation] = useState(localState.minNavigation)
    let [credits, setCredits] = useState()
    const headersRequest = {headers: {Authorization: `Bearer ${sessionState.idToken}`}}

    const handleClick = () => {
        HANDLE_TYPE(!minNavigation)
        setMinNavigation(!minNavigation)
    }

    useEffect(() => {
        currentAuthSession().then((response) => {
            REFRESH_COGNITO_TOKEN(response.idToken.toString(), response.idToken.payload.id_user, response.idToken.payload.id_team ? response.idToken.payload.id_team : undefined, response.idToken.payload.role)
        })
        sessionState.role !== "admin" && getTeamInfos(sessionState.idTeam, headersRequest).then((response) => {
            SET_LICENCE_PACKAGE(response.licence_package)
            setCredits(response.nb_credits)
        })
    }, [])

    return (
        <>
        <div className={`SECTION ${minNavigation ? "MIN-NW-LEFT-NAV" : "NW-LEFT-NAV"}`}>
            <img className="nw-logo" src={minNavigation ? neurowideStudioMin : neurowideStudioLogo} alt=""/>
            <img onClick={handleClick} className="nav-action-btn" src={doubleArrowLeftIcon} alt="" title={minNavigation ? t("Expand navigation menu") : t("Collapse navigation menu")}/>
            <span className="line"></span>
            <nav>
                <ul className="top-nav">
                    {sessionState.role === "admin" ?
                        <>
                        <RequireIsAdmin role={sessionState.role}>
                            <li><NavLink to="/neurowide-dashboard"><img src={manageAccountIcon} alt=""/><span>{t("Neurowide dashboard")}</span></NavLink></li>
                        </RequireIsAdmin>
                        </>
                        :
                        <>
                        <li title={t("Homepage")}><NavLink to="/" className="navlink"><img src={homeNavIcon} alt=""/><span>{t("Home")}</span></NavLink></li>
                        <li title={t("Projects")}><NavLink to="/projects" className="navlink"><img src={projectsNavIcon} alt=""/><span>{t("Projects")}</span></NavLink></li>
                        <li title={t("Lexicons")}><NavLink to="/lexicons" className="navlink"><img src={lexiconsNavIcon} alt=""/><span>{t("Lexicons")}</span></NavLink></li>
                        <RequireIsManager role={sessionState.role}>
                            <li title={t("Team management")}><NavLink to="/team-management" className="navlink"><img src={teamNavIcon} alt=""/><span>{t("Team management")}</span></NavLink></li>
                        </RequireIsManager>
                        {(teamsState.licencePackage && teamsState.licencePackage.url_label === "Nolej" && teamsState.licencePackage.url !== "") &&
                            <>
                            <span className="second-line"></span>
                            <li title={t("Nolej")} className="nolej-btn"><a href={teamsState.licencePackage.url} target="_blank" rel="noreferrer"><img className="nolej-logo" src={nolejLogo} alt=""/></a></li>
                            </>
                        }
                        </>
                    }
                </ul>
                <ul className="bottom-nav">
                    <li className="help" title={t("Help")}><Link to="/help"><img src={helpIcon} alt=""/><span>{t("Help")}</span></Link></li>
                    <li className="credit" title={t("Your credits")}><Link to="/"><img src={creditsNavIcon} alt=""/><span>{`${credits} ${t("credits available")}`}</span></Link></li>
                </ul>
            </nav>
        </div>
        </>
    )
}

export default connect(globalSelector, {REFRESH_COGNITO_TOKEN, HANDLE_TYPE, SET_LICENCE_PACKAGE})(MainNavigation)