import progressIcon from "../../assets/icon/progress.svg"

export default function LoadingComponent() {
    return (
        <>
        <div className="loading-component">
            <img className="icon" src={progressIcon} alt=""/>
        </div>
        </>
    )
}