import * as types from "../redux-constants/teamsConstants"

const teams = {
    isConfirmationModal: {modal: false, id: null, action: null},
    licencePackage: undefined,
}

export default function TeamsReducer(state=teams, action) {
    switch(action.type) {
        case types.TEAMS_IS_CONFIRMATION_MODAL:
            return {
                isConfirmationModal: action.payload.isConfirmationModal,
                licencePackage: state.licencePackage,
            }
        case types.SET_LICENCE_PACKAGE:
            return {
                isConfirmationModal: state.isConfirmationModal,
                licencePackage: action.payload.licencePackage,
            }
        case types.CLEAN_TEAMS_STATE:
            return {
                isConfirmationModal: {modal: false, id: null, action: null},
                licencePackage: undefined,
            }
        default:
            return state
    }
}