import {useEffect} from "react"
import {connect} from "react-redux"
import {localSelector} from "../../redux/selectors/localSelector"
import progressIcon from "../../assets/icon/progress.svg"
import {useTranslation} from "react-i18next"
import ScrollingMod from "../../tools/ScrollingMod"

function LoadingModal({localState, display, trie}) {
    const {t} = useTranslation()
    const steps = {
        "upload": "Media being uploaded",
        "lexicons": "Media being transcribed",
        "lexiconsDone": "Media being transcribed",
        "transcription": "Media being transcribed",
        "translation": "Media being translated",
        "render": "Project being render",
        "archive": "Project being render"
    }

    useEffect(() => {
        ScrollingMod(display)
    }, [display])

    return (
        <>
        <div className={`loading-modal ${display && "force-display"}`}>
            <div className="content" style={{left: localState.minNavigation && "50%"}}>
                <img className="icon" src={progressIcon} alt=""/>
                <h3>{trie && t(steps[trie.step])}</h3>
            </div>
        </div>
        </>
    )
}

export default connect(localSelector)(LoadingModal)