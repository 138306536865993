import {useEffect} from "react"
import {connect} from "react-redux"
import {REFRESH_COGNITO_TOKEN} from "../../redux/actions/sessionActions"
import currentAuthSession from "../../aws/cognito/currentAuthSession"
import {sessionSelector} from "../../redux/selectors/sessionSelector"
import {useTranslation} from "react-i18next"
import useProjects from "../../swrCustomHooks/useProjects"
import AddProjectBtn from "../../components/add-project-btn/AddProjectBtn"
import ProjectCard from "../../components/project-card/ProjectCard"
import LoadingComponent from "../../components/loading-component/LoadingComponent"

function HomeContent({sessionState, REFRESH_COGNITO_TOKEN, isRender}) {
    const {t} = useTranslation()
    const {projects, mutateProjects, isLoadingProjects, isErrorProjects} = useProjects(sessionState.idToken)

    useEffect(() => {
        currentAuthSession().then((response) => {
            response.idToken && REFRESH_COGNITO_TOKEN(response.idToken.toString(), response.idToken.payload.id_user, response.idToken.payload.id_team ? response.idToken.payload.id_team : undefined, response.idToken.payload.role)
        })
        mutateProjects()
    }, [isRender, projects])

    if (isErrorProjects || isLoadingProjects || projects.message) return <LoadingComponent/>
    return (
        <>
        <div className="SECTION HOME-CONTENT">
            <h2>{t("Last 5 projects")}</h2>
            <AddProjectBtn/>
            {projects.map((project, index) => {
                if (index < 5) return <ProjectCard key={`project-card-${index}`} project={project}/>
            })}
        </div>
        </>
    )
}

export default connect(sessionSelector, {REFRESH_COGNITO_TOKEN})(HomeContent)