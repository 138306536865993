import {useState} from "react"
import {connect} from "react-redux"
import {NavLink} from "react-router-dom"
import {localSelector} from "../../redux/selectors/localSelector"
import {LOGOUT} from "../../redux/actions/sessionActions"
import {SELECT_LANG} from "../../redux/actions/localActions"
import {CLEAN_TEAMS_STATE} from "../../redux/actions/teamsActions"
import i18n from "../../i18n/i18n"
import logout from "../../aws/cognito/logout"
import franceFlagImg from "../../assets/img/france.svg"
import englandFlagimg from "../../assets/img/england.svg"
import arrowDownBlack from "../../assets/icon/arrow-down-black.svg"
import logoutIcon from "../../assets/icon/logout.svg"
import ProfilePicture from "../../components/profile-picture/ProfilePicture"
import {useTranslation} from "react-i18next"

function Header({localState, LOGOUT, CLEAN_TEAMS_STATE, SELECT_LANG}) {
    const {t} = useTranslation()
    const [langsMenu, setLangMenu] = useState(false)

    const handleClick = () => {
        document.getElementById("arrow").style.transform = langsMenu ? "rotate(0deg)" : "rotate(180deg)"
        setLangMenu(!langsMenu)
    }

    const setLang = (lang) => {
        handleClick()
        SELECT_LANG(lang)
        i18n.changeLanguage(lang)
    }

    const disconnect = async () => {
        await logout().then(() => {
            CLEAN_TEAMS_STATE()
            LOGOUT()
        })
    }

    return (
        <>
        <header className="SECTION HEADER">
            <nav className="nw-header-nav">
                <ul>
                    <ul className="langs" style={{borderRadius: `${langsMenu ? "18px" : "36px"}`}} title={t("Select your language")}>
                        {localState.lang === "fr-FR" ?
                            <li onClick={() => setLang("fr-FR")} className="lang"><img className="flag" src={franceFlagImg} alt=""/>FR</li>
                            :
                            <li onClick={() => setLang("en-US")} className="lang"><img className="flag" src={englandFlagimg} alt=""/>EN</li>
                        }
                        {langsMenu ?
                            localState.lang === "fr-FR" ?
                                <li onClick={() => setLang("en-US")} className="lang"><img className="flag" src={englandFlagimg} alt=""/>EN</li>
                                :
                                <li onClick={() => setLang("fr-FR")} className="lang"><img className="flag" src={franceFlagImg} alt=""/>FR</li>
                        : ""}
                        <img onClick={handleClick} id="arrow" className="arrow" src={arrowDownBlack} alt=""/>
                    </ul>
                    <li title={t("Profile")}><NavLink to="/profile"><ProfilePicture/></NavLink></li>
                    <li className="logout" onClick={disconnect} title={t("Disconnect")}><img src={logoutIcon} alt=""/></li>
                </ul>
            </nav>
        </header>
        </>
    )
}

export default connect(localSelector, {LOGOUT, CLEAN_TEAMS_STATE, SELECT_LANG})(Header)