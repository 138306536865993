import {useRef} from "react"
import arrowRightBlackIcon from "../../assets/icon/arrow-right-black.svg"

export default function HelpAccordion({question, isOpen, handleClick}) {
    const contentHeight = useRef()
    return (
        <>
        <div className="help-accordion">
            <div className="header" onClick={handleClick}>
                <h3>{question.question}</h3>
                <img className={isOpen && "rotate"} src={arrowRightBlackIcon} alt=""/>
            </div>
            <div ref={contentHeight} className="content" style={{maxHeight: isOpen ? contentHeight.current.scrollHeight : "0px"}}>
                <p>{question.answer}</p>
            </div>
        </div>
        </>
    )
}