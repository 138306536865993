import axios from "axios"
import useSWR from "swr"
import {API_PROJECTS} from "../constants/apiUrls"

export default function useCountProjects(tokenID, params) {
    const url = `${API_PROJECTS}/count?` + (params === "my-projects" ? "public=false" : params === "shared-projects" ? "public=true" : params === "recycle-bin" ? "deleted=true" : null)

    const fetcher = (args) =>
        axios.get(args.url, {headers: {Authorization: `Bearer ${args.token}`}})
        .then((response) => {
            return response.data
        })

    const {data, mutate, error} = useSWR({url: url, token: tokenID}, fetcher)

    return {
        countProjects: data,
        mutateCountProjects: mutate,
        isLoadingCountProjects: !error && !data,
        isErrorCountProjects: error
    }
}