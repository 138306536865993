import {useState} from "react"
import {useTranslation} from "react-i18next"
import {connect} from "react-redux"
import {localSelector} from "../../redux/selectors/localSelector"
import HelpAccordion from "../../components/help-accordion/HelpAccordion"
import questionsFR from "../../data/faq-FR.json"
import questionsEN from "../../data/faq-EN.json"

function HelpQuestions({localState}) {
    const {t} = useTranslation()
    const [activeIndex, setActiveIndex] = useState(null)

    const handleClick = (index) => {
        setActiveIndex((prevIndex) => (prevIndex === index ? null : index))
    }

    return (
        <>
        <div className="SECTION HELP-QUESTIONS">
            <h2>{t("Frequently asked questions")}</h2>
            <ul className="help-accordions">
                {localState.lang === "fr-FR" ?
                    <>
                    {questionsFR.map((question, index) => (
                        <HelpAccordion key={`help-question-${index}`} question={question} isOpen={activeIndex === index} handleClick={() => handleClick(index)}/>
                    ))}
                    </>
                    :
                    <>
                    {questionsEN.map((question, index) => (
                        <HelpAccordion key={`help-question-${index}`} question={question} isOpen={activeIndex === index} handleClick={() => handleClick(index)}/>
                    ))}
                    </>
                }
            </ul>
        </div>
        </>
    )
}

export default connect(localSelector)(HelpQuestions)